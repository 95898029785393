import { defineStore } from 'pinia';
import filters from '@/composables/store/filters';
/* eslint-disable import/prefer-default-export */
import useFormValidation from '@/composables/validations/useFormValidation';
import useLocalStorage from '@/composables/storage/useLocalStorage';
import useGlobalActions from '@/composables/store/globalActions';

const { validateEmailField, validateMobileField, validateCheckboxField } = useFormValidation();
const { updateErrorFieldByKey } = useGlobalActions();

const { checkLocalStorage, bindLocalStorage } = useLocalStorage();
const { formHasErrors, getErrorByField, getFormPayload } = filters();

const formObject = {
  email: {
    value: null,
    error: null,
    validator: validateEmailField,
    required: true,
  },
  country: {
    value: '',
    required: true,
  },
  mobile: {
    value: null,
    required: true,
    validator: validateMobileField,
    error: '',
  },
  phoneCountry: {
    value: 'US',
    required: true,
  },
  internationalCode: {
    value: '+1',
    required: true,
  },
  marketing: {
    value: null,
  },
  tnc: {
    value: null,
    error: null,
    validator: validateCheckboxField,
    required: true,
  },
};

export const useVerificationStore = defineStore({
  id: 'verification',
  state: () => ({
    form: checkLocalStorage('verification', formObject),
  }),
  getters: {
    formHasErrors: (store) => formHasErrors(store),
    getErrorByField: (store) => (field) => getErrorByField(store)(field),
    getFormPayload: (store) => store.form && getFormPayload(store),
  },
  actions: {
    updateValue(field, val) {
      if (!Object.getOwnPropertyDescriptor(this.form, field)) {
        return;
      }

      if (Object.getOwnPropertyDescriptor(this.form[field], 'validator')) {
        const { validator } = this.form[field];
        if (field === 'mobile') {
          this.form.mobile.error = validator(val, this.form.phoneCountry.value);
        } else {
          this.form[field].error = validator(field, val);
        }
      }

      // HE-1086: This is to avoid the conflict 'libphonenumber-js' library that causes with double '+`'
      if (field === 'internationalCode') {
        this.form[field].value = val.replace(/\+{2,}/g, '+');
      } else this.form[field].value = val;

      bindLocalStorage(this.$id, this.form);
    },
    removeFields(...fields) {
      fields.forEach((field) => {
        if (Object.getOwnPropertyDescriptor(this.form, field)) {
          delete this.form[field];
        }
      });

      bindLocalStorage(this.$id, this.form);
    },
    reAddFields(...fields) {
      fields.forEach((field) => {
        if (!Object.getOwnPropertyDescriptor(this.form, field)) {
          this.form[field] = formObject[field];
        }
      });

      bindLocalStorage(this.$id, this.form);
    },

    updateErrorFieldByKey(field, error) {
      updateErrorFieldByKey(this.form, field, error);
    },
  },
});

import { useOrderFormStore } from '@/stores/forms/orderFormStore';

function expressCheckoutEnabled() {
  const orderStore = useOrderFormStore();
  return orderStore.merchApiOrderData?.redirectTo === 'payment' || false;
}

function handleExpressCheckout(router) {
  if (!expressCheckoutEnabled()) return;
  router.push('/payment');
}

export { handleExpressCheckout, expressCheckoutEnabled };

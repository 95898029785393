{
    "BuySellToggle.label.buy": "Buy coins",
    "BuySellToggle.label.sell": "Sell coins",
    "MainSettingSidebar.label.header": "Configure your experience",
    "MainSettingSidebar.label.subHeader": "Make it yours",
    "MainSettingSidebar.label.light": "Light",
    "MainSettingSidebar.label.dark": "Dark",
    "CurrencyInput.label.spend": "Spend",
    "CurrencyInput.label.receive": "Receive",
    "WalletAddressField.label.walletAddress": "Your wallet address",
    "WalletAddressField.placeholder.walletAddress": "Enter your wallet address",
    "FormSelect.label.blockchain": "Selected blockchain",
    "PaymentInput.label.paymentMethod": "Payment method",
    "OrderSummary.label.summary": "Summary",
    "OrderSummary.label.hideSummary": "Hide full summary",
    "OrderSummary.label.processingFee": "Processing Fee",
    "OrderSummary.label.networkFee": "Estimated Network Fee",
    "OrderSummary.label.totalPayable": "Total Payable",
    "CurrencyInput.placeholder.spend": "Fiat amount",
    "CurrencyInput.placeholder.receive": "Coin amount",
    "FormInputField.label.walletTag": "Your wallet tag",
    "FormInputField.placeholder.walletTag": "Enter your wallet tag",
    "ButtonBase.label.createOrder": "Create Order",
    "CurrencyInput.label.sell": "Sell",
    "EmailField.label.emailAddress": "Email Address",
    "EmailField.error.emailAddressErrorMsg": "The input is not a valid email address",
    "MobileAutocompleteSelect.label.phoneCountry": "Mobile Country ",
    "MobileField.label.mobileNumber": "Mobile Number",
    "Autocomplete.label.countryResidence": "Country of residence *",
    "MobileField.error.mobileNumberErrorMsg": "Invalid mobile number for the country code",
    "Checkbox.text.marketing": "Yes, I would like to be kept up to date with promotions and marketing. (You can opt out at any time.)",
    "Checkbox.text.verificationTnc": "I have read and agree to the ((https:\/\/banxa.com\/terms-of-use,terms & conditions)) and acknowledge the ((https:\/\/banxa.com\/privacy-policy,Privacy and Cookies policy)).",
    "Checkbox.text.consumerReport": "I authorise Banxa to obtain my consumer reports from consumer reporting agencies for the purposes of fraud prevention and compliance with laws.",
    "ButtonBase.label.submitVerification": "Submit my verification",
    "PageTitle.headline.verificationPageHeader": "Let's verify your details",
    "PageSubTitle.paragraph.verificationInstructions": "If you've used Banxa before, enter the same email to skip extra steps.{br}{br}New To banxa? Just enter your email to set it up for easy purchases in the future.",
    "PageTitle.headline.verificationCodeCheckMobileTitle": " Check your mobile",
    "PageSubTitle.smallHeadline.incoming": "Incoming",
    "PageTitle.headline.verificationCodeCheckEmailTitle": "Check your email",
    "PageSubTitle.paragraph.verificationCodeInstructions": "We’ve sent a four digit code to {mobileOrEmailAddress}. Enter it below to verify your account.",
    "VerificationCodeInputField.error.verificationCodeErrorMsg": "The verification code is invalid",
    "ButtonBase.label.verifyMe": "Verify Me",
    "PageSubTitle.paragraph.statusInstructions": "It looks like you've done everything you need to. We'll work on fulfilling your order and email you as your order progresses.",
    "PageTitle.headline.statusTitle": "Hooray!",
    "PageSubTitle.smallHeadline.hipHip": "Hip-hip",
    "SummaryStatusIcon.text.verificationStatusTitle": "Verification",
    "SummaryStatusIcon.label.verificationStatusLabel": "Completed",
    "SummaryStatusIcon.text.paymentStatusTitle": "Payment",
    "SummaryStatusIcon.text.identityStatusTitle": "Identity",
    "SummaryStatusIcon.text.fulfillmentStatusTitle": "Fulfillment",
    "SummaryStatusIcon.label.completedStatusLabel": "Completed",
    "SummaryStatusIcon.label.expiredStatusLabel": "Expired",
    "SummaryStatusIcon.label.cancelledStatusLabel": "Cancelled",
    "SummaryStatusIcon.label.declinedStatusLabel": "Declined",
    "SummaryStatusIcon.label.fraudStatusLabel": "Fraud",
    "SummaryStatusIcon.label.refundedStatusLabel": "Refunded",
    "SummaryStatusIcon.label.actionRequiredStatusLabel": "Action Required",
    "SummaryStatusIcon.label.underReviewStatusLabel": "Under Review",
    "SummaryStatusIcon.label.preparingOrderStatusLabel": "Preparing Order",
    "SummaryStatusIcon.label.processingStatusLabel": "Processing",
    "SummaryTable.title.Order": "Order",
    "SummaryTable.row.timeToFulfill": "Approximate time to fulfil",
    "SummaryTable.row.paymentAmount": "Payment amount",
    "SummaryTable.row.amountToReceive": "Amount to receive",
    "SummaryTable.row.walletAddress": "Wallet Address",
    "SummaryTable.row.selectedBlockchain": "Selected Blockchain",
    "SummaryTable.row.paymentMethod": "Payment method",
    "SummaryTable.row.networkFees": "Network Fee",
    "SummaryTable.row.processingFees": "Processing Fee",
    "SummaryTable.row.commissionFees": "Commission fees",
    "ButtonBase.label.createANewOrder": "Create A New Order",
    "Footer.label.disclaimer": "*The estimated network fee is an approximation and the actual network fee applied on a withdrawal may differ. The actual network fee can vary due to blockchain conditions like network congestion and demand at the time of the transaction. Orders may not be canceled or reversed once submitted by you. Also, if a withdrawal request is being made, you are requesting an on-chain transaction that is not reversible or recallable. You are responsible for reviewing the recipient address and ensuring it is the correct address for the selected asset for withdrawal. Fraudulent transactions may result in the loss of your money with no recourse.",
    "Footer.label.legalAndCompliance": "Legal and Compliance",
    "Footer.label.supportCenter": "Support center",
    "Footer.label.authorised": "Banxa is not a bank \/ authorised deposit taking institution.",
    "ProgressBar.label.verify": "Verify",
    "ProgressBar.text.verify": "Let's make sure you're real by verifying your details. If you've transacted with us before, use the same email and mobile number to checkout faster.",
    "ProgressBar.label.identify": "Identity",
    "ProgressBar.text.identify": "For new customers, some identification documents may be required. The process should only take a couple of minutes and you’ll need to upload a photo of either a valid drivers licence, passport or national ID.",
    "ProgressBar.label.payment": "Payment",
    "ProgressBar.label.deposit": "Deposit",
    "ProgressBar.text.payment": "Securely complete your payment and receive your coins within minutes.",
    "ProgressBar.text.deposit": "Follow the instructions to complete the transaction and receive fund in your account",
    "OrderSummary.label.show": "Show",
    "OrderSummary.label.hide": "Hide",
    "OrderSummary.label.fullSummary": "Full summary",
    "OrderView.label.zeroHashTerm": "Cryptocurrency services powered by Zero Hash",
    "NFTPreview.label.aboutToBuy": "You're about to buy",
    "NFTPreview.label.fees": "fees",
    "NFTBuyForm.label.nft": "Let's make this NFT yours.",
    "NFTCurrencySelector.label.spend": "Spend",
    "NFTCurrencySelector.placeholder.spend": "Fiat amount",
    "NFTSummary.label.congratulation": "Congratulations! You've bought",
    "NFTSummary.label.order": "Order",
    "NFTSummary.label.nftName": "NFT name",
    "NFTSummary.label.nftPrice": "NFT price",
    "NFTSummary.label.paymentMethod": "Payment method",
    "NFTSummary.label.networkFee": "Network fee",
    "NFTPreview.error.40441": "The checkout link has expired. Please go back and try again. (40441)",
    "OrderAccordion.label.spend": "You spend",
    "OrderAccordion.label.receive": "You receive",
    "OrderAccordion.label.processingFee": "Processing Fee",
    "OrderAccordion.label.networkFee": "Network Fee",
    "MobileAutocompleteSelect.placeholder.phoneCountry": "Select your country of residence",
    "AutocompleteSelect.placeholder.typeToSearch": "Type to search",
    "PageTitle.headline.identityConfirmationTitle": "Add billing details",
    "FormInputField.label.givenName": "First name *",
    "FormInputField.placeholder.givenName": "Enter your first name",
    "FormInputField.label.middleName": "Middle Name",
    "FormInputField.label.lastName": "Last Name *",
    "FormInputField.label.dob": "Date of Birth *",
    "FormInputField.label.addressLine1": "Residential Address *",
    "FormInputField.guide.addressLine1": "Manually enter your address instead",
    "FormInputField.label.suburb": "City \/ Suburb \/ Town *",
    "FormSelect.label.usStates": "Select a state *",
    "FormInputField.label.state": "State \/ County \/ Province *",
    "FormInputField.label.taxId": "SSN",
    "FormInputField.label.postcode": "Post Code *",
    "FormSelect.label.occupationDropdown": "Select your occupation *",
    "FormSelect.label.occupationIndustryDropdown": "Select your occupation industry *",
    "FormSelect.placeholder.occupation": "Your occupation",
    "FormInputField.label.occupationOther": "Please specify *",
    "FormInputField.placeholder.occupationOther": "Enter your occupation",
    "Checkbox.text.identificationConfirmationTnc": "I have read and agree to the ((https:\/\/banxa.com\/terms-of-use , terms & conditions)) and acknowledge the ((https:\/\/banxa.com\/privacy-policy , Privacy and Cookies policy))\n\nI agree to the ((https:\/\/www.zerohash.com\/banxa , Zero Hash and Zero Hash Liquidity Services User Agreement)), and I have read and understand the Zero Hash ((https:\/\/zerohash.zendesk.com\/hc\/en-us\/articles\/360009900494-Privacy-Policy, Privacy Policy)) and ((https:\/\/zerohash.zendesk.com\/hc\/en-us\/articles\/360008819314-State-Money-Transmitter-Disclosures, Regulatory Disclosures)). I understand that the value of any cryptocurrency, including digital assets pegged to fiat currency, commodities, or any other asset, may go to zero.",
    "BtcdepositForm.label.send": "Please send {coinAmount} to the following wallet address to receive {fiatAmount}",
    "BtcdepositForm.label.scanQR": "Scan the QR code or copy the wallet address below into your crypto wallet.",
    "BtcdepositForm.label.useWallet": "Use your wallet to scan this code or copy the address below",
    "ButtonBase.label.deposited": "I have deposited the coins",
    "BtcdepositForm.label.walletAddress": "Wallet address",
    "BtcdepositForm.label.amountToSend": "Amount to send",
    "HashpackdepositForm.label.connectYourWallet": "Connect your wallet with {connectWallet} button below. Please ensure that your wallet is on the correct network.",
    "HashpackdepositForm.label.amountToSell": "Amount to sell",
    "HashpackdepositForm.label.walletConnected": "Wallet connected",
    "HashpackdepositForm.label.estimatedGasFee": "Estimated Gas Fee",
    "HashpackdepositForm.label.estimating": "Estimating",
    "HashpackdepositForm.label.completeTransaction": "To complete your transactions, click the {coinAmount} button below. Please ensure that your wallet is on the correct network.",
    "HashpackdepositForm.label.sell": "Sell",
    "HashpackdepositForm.label.connectWallet": "Connect Wallet",
    "HashpackdepositForm.label.insufficientBalance": "Insufficient Balance",
    "HashpackdepositForm.label.failedEstimateFee": "Failed to estimate fee",
    "ButtonBase.label.connectWallet": "Connect to {itemName} Wallet",
    "MetamaskButton.label.connect": "Connect to Metamask",
    "ZksyncdepositForm.label.connectYourWallet": "Connect your wallet with {connectWallet} button below. Please ensure that your wallet is on the correct network.",
    "ZksyncdepositForm.label.connectWallet": "Connect Wallet",
    "ZksyncdepositForm.label.completeTransaction": "To complete your transactions, click the {coinAmount} button below. Please ensure that your wallet is on the correct network.",
    "ZksyncdepositForm.label.sell": "Sell",
    "ZksyncdepositForm.label.insufficientBalance": "Insufficient Balance",
    "ZksyncdepositForm.label.amountToSell": "Amount to sell",
    "ZksyncdepositForm.label.walletConnected": "Wallet connected",
    "ZksyncdepositForm.label.estimatedGasFee": "Estimated Gas Fee",
    "ZksyncdepositForm.label.estimating": "Estimating",
    "ZksyncdepositForm.label.failedEstimateFee": "Failed to estimate fee",
    "CoinDepositForm.label.verify": "Verifying your details",
    "CoinDepositForm.label.deposit": "Deposit your coins",
    "CoinDepositForm.label.pleaseWait": "Please wait while we verify your identity details. This can take anywhere between 2 - 30 minutes. You can return to this page at any time. Once verified, you'll be able to make a deposit.",
    "WalletModal.label.continue": "Before we continue",
    "WalletModal.label.confirm": "Confirm your wallet",
    "WalletModal.label.body": "Cryptocurrency transactions are final. To ensure your coins reach you safely, please verify that the wallet below is the one you intend to use",
    "WalletModal.label.ask": "Don't know what this means?",
    "WalletModal.label.faqs": "Check out our FAQ's",
    "ButtonBase.label.editWalletAddress": "Edit my wallet address",
    "ButtonBase.label.continueVerification": "Continue to Verification",
    "WalletModal.label.gotIt": "Got it!",
    "SuccessModal.label.verification": "Verification",
    "SuccessModal.label.complete": "Complete",
    "SuccessModal.label.identity": "Identity",
    "SuccessModal.label.payment": "Payment",
    "ButtonBase.label.submitMyDetails": "Submit my details",
    "FormInputField.error.dobErrorMsg": "Please enter a valid date as DD-MM-YYYY",
    "PageTitle.headline.dLocalIdentityCurp": "Your Mexican Identity Card",
    "PageTitle.headline.dLocalIdentitySaid": "Your South African Identity Card",
    "PageTitle.headline.dLocalIdentityCpf": "Your Cadastro de Pessoas Físicas (CPF)",
    "PageTitle.headline.dLocalIdentityCC": "Your Cédula de Ciudadanía (CC)",
    "PageTitle.headline.dLocalIdentityDni": "Your Documento Nacional de Identidad (DNI)",
    "PageTitle.headline.dLocalIdentityCi": "Your Cédula de identidad (CI)",
    "PageTitle.headline.dlocalIdentityTic": "Your Thai Identity Card (Thai National ID card)",
    "FormInputField.label.curpNumber": "Your CURP number *",
    "FormInputField.placeholder.curpNumber": "CURP number",
    "FormInputField.placeholder.saidNumber": "SAID number",
    "FormInputField.label.saidNumber": "Your SAID number *",
    "FormInputField.label.cpfNumber": "Your CPF number *",
    "FormInputField.placeholder.cpfNumber": "CPF number",
    "FormInputField.label.ccNumber": "Your CC number *",
    "FormInputField.placeholder.ccNumber": "CC number",
    "FormInputField.label.dniNumber": "Your DNI number *",
    "FormInputField.placeholder.dniNumber": "DNI number",
    "FormInputField.label.ciNumber": "Your CI number *",
    "FormInputField.placeholder.ciNumber": "CI number",
    "FormInputField.label.ticNumber": "Your Thai National ID card number *",
    "FormInputField.placeholder.ticNumber": "Thai National ID card number",
    "ButtonBase.label.submit": "Submit",
    "FormInputField.error.curpErrorMsg": "Please enter a valid CURP number",
    "FormInputField.error.saidErrorMsg": "Please enter a valid SAID number",
    "FormInputField.error.cpfErrorMsg": "Please enter a valid CPF number",
    "FormInputField.error.ccErrorMsg": "Please enter a valid CC number",
    "FormInputField.error.dniErrorMsg": "Please enter a valid DNI number",
    "FormInputField.error.ciErrorMsg": "Please enter a valid CI number",
    "FormInputField.error.ticErrorMsg": "Please enter a valid Thai National ID card number",
    "FileUploadField.label.idCard": "Upload photo page of id card",
    "FileUploadField.placeholder.selectOrDrag": "Select or drag your file",
    "FileUploadField.error.fileTypeErrorMsg": "Please upload a JPG, JPEG, or PNG type file",
    "FileUploadField.error.fileSizeErrorMsg": "Your uploaded document's size should be less than 10 MB",
    "FileUploadField.error.idCardMultiFileSizeErrorMsg": "Please upload your documents as JPG, JPEG, or PNG type file",
    "MultiFileUploadField.placeholder.genericUploadPlaceholder": "Select or drag your file",
    "MultiFileUploadField.label.genericUploadLabel": "Please select all your documents in one go",
    "Checkbox.text.sofDocumentUploadTnc": "I hereby declare that the supporting documents I have provided are true and complete.",
    "FormAlert.text.documentUploadInfo": "Failure to upload proper documents to prove your source of funds will\n      result in your order being put on hold and significantly delayed.",
    "PageTitle.headline.SofDocumentUploadPageHeader": "Source of funds document upload",
    "PageSubTitle.paragraph.sofDociumentUploadInstructions": "Please upload the following documents to verify your source of funds.",
    "PageSubTitle.paragraph.sofDociumentUploadInstructions2": "Past 3 month’s bank statement.','Recent payslips.'",
    "ButtonBase.label.sofSelfie": "My selfie is clear and legible",
    "FileUploadField.label.driversLicenceFront": "Upload front of licence",
    "FileUploadField.label.driversLicenceBack": "Upload back of licence",
    "MultiFileUploadField.error.fileSizeLimitErrorMsg": "Your total uploaded document's size should be less than 10 MB",
    "MultiFileUploadField.error.fileTypesAllowedErrorMsg": "Please upload your documents as JPG, JPEG, or PNG type file",
    "FileUploadField.label.passport": "Upload photo page of passport",
    "FileUploadField.label.proofOfResidency": "Upload your proof of residency",
    "PageTitle.headline.documentNumberForm": "Identity Document",
    "PageSubTitle.paragraph.documentNumberInstructions": "Please enter your Identity number",
    "FormSelect.label.documentType": "Identity document",
    "FormInputField.label.documentNumber": "Identity number",
    "FormInputField.placeholder.documentNumber": "Enter your Identity Number",
    "ButtonBase.label.continue": "Continue",
    "ButtonBase.label.i.agree": "I Agree",
    "PageTitle.headline.IdentificationUpload": "Let’s confirm your identity.",
    "PageSubTitle.paragraph.identificationUploadInstructions": "In order to purchase cryptocurrency we’re legally required to collect some identification from you.",
    "FormSelect.label.identityDocumentCountry": "Country that issued your identity document",
    "ButtonBase.label.submitMyIdentity": "Submit my identity",
    "PageTitle.headline.occupationTitle": "Your occupation",
    "FormInputField.error.mustNotContainSpecialCharsErrorMsg": "Must not contain special characters",
    "FormInputField.error.requiredErrorMsg": "This field is required",
    "PageSubTitle.paragraph.dLocalInstructions": "To progress with your order, please provide your",
    "FormInputField.placeholder.postcode": "Your post code",
    "FormInputField.placeholder.state": "Your state",
    "FormInputField.placeholder.suburb": "Your city",
    "FormInputField.placeholder.addressLine1": "Current residential address",
    "FormInputField.placeholder.surname": "Enter your last name",
    "FormInputField.placeholder.middleName": "Enter your middle name",
    "WorldpaycreditForm.label.usePersonalCard": "Use your personal credit card, not a company cards.",
    "WorldpaycreditForm.label.useCard": "Please only use a card in your name.",
    "ButtonBase.label.selectCard": "Select a new card",
    "Checkbox.text.saveCard": "Save this card for future purchases",
    "FormAlert.text.previouslySavedCard": "You have previously saved your card for faster checkouts. Select the card or choose a new card.",
    "PaymentForm.label.paymentDetails": "Payment details",
    "PaymentForm.label.depositDetails": "Deposit details",
    "PaymentForm.label.instruction": "Complete the below to securely finalize your transaction.",
    "FormInputField.label.name": "Name",
    "FormInputField.placeholder.name": "Name",
    "BaseInput.label.previousCard": "Your Previously Used Card",
    "FormInputField.label.securityCode": "Enter Security Code",
    "FormInputField.placeholder.securityCode": "CVV",
    "ButtonBase.label.placeOrder": "Place Order",
    "CheckoutcreditForm.label.usePersonalCard": "Use your personal credit card, not a company card.",
    "CheckoutcreditForm.label.useCard": "Please only use a card in your name.",
    "ButtonSecondary.label.addCard": "Add New Card",
    "ButtonSecondary.label.existingCard": "Use an existing card",
    "FormInputField.label.cardholderName": "Cardholder Name",
    "FormInputField.placeholder.cardholderName": "Cardholder Name",
    "BaseInput.label.cardNumber": "Card Number",
    "BaseInput.label.expiryDate": "Expiry Date",
    "BaseInput.label.ccvNumber": "CVV Number",
    "Checkbox.text.rememberCard": "Remember my card details for faster, future purchases.",
    "AchForm.headline.pay": "Pay using ACH",
    "AchForm.label.clickConnect": "Click \"Connect\" button to connect your account with Plaid to complete payment through ACH.",
    "Ach.ul.list": "Use your personal account, not a company account.",
    "CircleachForm.headline.pay": "Pay using ACH",
    "CircleachForm.label.clickConnect": "Click \"Connect\" button to connect your account with Plaid to complete payment through ACH.",
    "Circleach.ul.list": "Use your personal account, not a company account.",
    "Checkbox.text.auth": "I, {customerName}, authorize BANXA to electronically debit my Depository account for a one-time electronic funds transfer (EFT) payment for the amount and on the date identified. I also authorize BANXA to debit or credit my account to correct any errors or process any refunds, if necessary.",
    "Checkbox.text.zhach.tc.1": "Today, being {date}, by clicking \"I Agree\", I authorize Zero Hash LLC (Zero Hash), in furtherance of the Zero Hash & ZHLS User Agreement, to debit my bank account via an electronic fund transfer for each payment for my cryptocurrency transaction, and any subsequent transactions, through the BANXA desktop or mobile application(s) or website(s).",
    "Checkbox.text.zhach.tc.2": "I also authorize Zero Hash to debit or credit my account for refunds or to correct any errors, if necessary. I understand that if payment is returned unpaid, it may be represented to my bank up to two times and I may be subject to and responsible for a return fee in accordance with applicable state law.",
    "Checkbox.text.zhach.tc.3": "I understand that this authorization shall remain in full force and effect until I notify Zero Hash by emailing at support{'@'}zerohash.com and informing Zero Hash that I wish to revoke this authorization. I understand that Zero Hash requires 15 days prior notice to cancel this authorization.",
    "Checkbox.text.zhach.tc.4": "Further, by clicking \"I Agree\", I acknowledge that I have read and understand the Electronic Fund Transfer Act Disclosures and the Plaid Inc. Privacy Policy.",
    "Checkbox.text.zhach.tc.5": "I expressly confirm that I have read, agree to, and consent to be bound by all of the terms of this authorization. By electronically signing this authorization, which may be completed by all methods of “clickwrap” or “click through” including by accepting, clicking a button, or checking a box, I acknowledge and agree that such electronic signature is valid evidence of my authorization.",
    "Checkbox.text.zhach.tc.6": "Please print or save a copy of this authorization for your records. Alternatively, contact Zero Hash at support{'@'}zerohash.com to learn how you can obtain a copy.",
    "Circleach.label.pleaseWait": "Please wait while we verify your account. It would take no more than 30 sec.",
    "CircleachForm.label.bankDetails": "Your bank account details:",
    "TwoColumnTable.label.bankName.zhach": "Bank Name:",
    "TwoColumnTable.label.accountHolderName.zhach": "Account Holder Name:",
    "TwoColumnTable.label.accountNumber.zhach": "Full or Last Four Digits of the Account Number:",
    "TwoColumnTable.label.bankName": "Bank name",
    "TwoColumnTable.label.accountType": "Account type",
    "TwoColumnTable.label.accountNumber": "Account number",
    "Circleach.error.failedLink": "Failed to link your ACH account. Please try again. If the issue persists, please connect with our support team. (4015) ",
    "Zerohash.ach.error.failedLink": "Failed to link your ACH account. Please try again. If the issue persists, please connect with our support team. (4015) ",
    "Circleach.error.errorPayment": "Error in this payment method. Please try again. If the issue persists, please connect with our support team. (4015)",
    "CircleachsellForm.label.clickConnect": "Click \"Connect\" button to connect your account with Plaid so we can deposit to you through ACH.",
    "ButtonBase.label.connect": "Connect",
    "Clearjcnsell.label.enterDetails": "Enter the details of the bank account where you'd like us to deposit your funds. Make sure the account is a personal account in your name (no business accounts).",
    "Clearjcnsell.ul.list": "Ensure your details are correct to avoid delays.",
    "Clearjcnsell.ul.list2": "Incorrect bank details will incur a £15.00 administration fee which will be deducted from your order.",
    "FormInputField.label.accountName": "Account Name*",
    "FormInputField.placeholder.accountName": "Enter name on your account",
    "FormInputField.label.sortCode": "Sort Code*",
    "FormInputField.placeholder.sortCode": "Enter sort code",
    "FormInputField.label.accountNumber": "Account Number*",
    "FormInputField.placeholder.accountNumber": "Enter account number",
    "Checkbox.text.rememberBankDetails": "Remember my bank details",
    "ButtonSecondary.label.useExistingBank": "Use existing bank account",
    "ButtonBase.label.continueDetails": "Continue with new details",
    "ButtonBase.label.submitBankDetails": "Submit bank details",
    "Clearjcnsell.label.headline": "Use Existing Bank Account",
    "ButtonSecondary.label.addNewBank": "Add new bank details",
    "TwoColumnTable.label.accountName": "Account Name",
    "TwoColumnTable.label.sortCode": "Sort Code",
    "Clearjcnsell.error.invalidBankDetails": "Invalid Bank Details",
    "FormInputField.label.iban": "IBAN*",
    "FormInputField.placeholder.iban": "Enter your IBAN",
    "TwoColumnTable.label.iban": "IBAN",
    "ClearjunctionForm.label.headline": "Transfer using SEPA",
    "Clearjunction.label.pleaseDeposit": "Please deposit {amountPaid} into the following bank account with the {referenceNumber} in the deposit reference field.",
    "Clearjunction.label.referenceNumber": "reference number",
    "Clearjunction.label.note": "NOTE: SWIFT or foreign currency deposits are not accepted.",
    "OpbankSepaForm.label.headline": "Transfer using SEPA",
    "Opbank.label.pleaseDeposit": "Please deposit {amountPaid} into the following bank account with the {referenceNumber} in the deposit reference field.",
    "Opbank.label.referenceNumber": "reference number",
    "OpbankSepa.label.note": "NOTE: SWIFT or foreign currency deposits are not accepted.",
    "TwoColumnTable.label.attachScreenshot": "Attach a screenshot of your receipt below",
    "ButtonBase.label.madeTransfer": "I have made the transfer",
    "TwoColumnTable.label.amountToPay": "Amount to pay",
    "TwoColumnTable.label.depositReference": "Deposit reference",
    "TwoColumnTable.label.receiver": "Beneficiary\/Receiver name",
    "TwoColumnTable.label.recipientAddress": "Recipient address",
    "TwoColumnTable.label.bankAddress": "Bank address",
    "TwoColumnTable.label.bic": "BIC",
    "OpbankfpForm.label.headline": "Transfer using Faster Payments",
    "ClearjunctionfpForm.label.headline": "Transfer using Faster Payments",
    "TwoColumnTable.label.payee": "Payee \/ Receiver name",
    "TwoColumnTable.label.referenceNumber": "Reference number",
    "PageTitle.headline.proofOfAddressPageHeader": "Proof of address",
    "PageSubTitle.paragraph.proofOfAddressInstructions": "Please submit your proof of address document.",
    "PageTitle.headline.pepSofPageHeader": "Source of funds questionnaire for politically exposed persons",
    "PageSubTitle.paragraph.whatIsSofQuestion": "What is source of funds?",
    "PageSubTitle.paragraph.whatIsSofAnswer": "Source of funds basically explains where the “money” you have used and will be using to fund transactions. This is to ensure that your funds are from legitimate sources.",
    "PageSubTitle.paragraph.whyAmIReceivingQuestion": "Why am I receiving this questionnaire?",
    "PageSubTitle.paragraph.whyAmIReceivingAnswer": "You are receiving this questionnaire because you or an immediate family member of yours have been identified as a politically exposed person.",
    "PageSubTitle.paragraph.whatIsAPepQuestion": "What is a politically exposed person (PEP)?",
    "PageSubTitle.paragraph.whatIsAPepAnswer": "A PEP is an individual entrusted with prominent public position or function for example a role in the goverment body, a state owned enterprise or an international organisation. Immediate family members and close associates of these individuals are also considered PEPs.\n          The SoF questionaire is mandatory, so please answer ALL questions and\n          provide the requested supporting documents.",
    "ButtonBase.label.yes": "YES",
    "ButtonBase.label.no": "NO",
    "PageSubTitle.paragraph.dualCitizenshipQuestion": "Do you have dual citizenship?",
    "FormSelect.label.citizenshipCountries": "Please select your dual citizenship countries:",
    "PageSubTitle.paragraph.pepRelatedByEmploymentQuestion": "Is your PEP status or your family member's PEP status related to your employment?",
    "PepSourceOfFunds.paragraph.pepRelatedByEmployment": "These questions are applicable when you pep status is related to your employment.",
    "BaseInput.label.jobTitle": "Your job title",
    "BaseInput.placeholder.jobTitle": "Enter your job title",
    "BaseInput.label.organization": "The Name of Your Employer Or Organization:",
    "BaseInput.placeholder.organization": "Enter your industry",
    "BaseInput.placeholder.industryType": "Enter your industry",
    "BaseInput.label.industryType": "Relationship with PEP (applicable if you are a family member):",
    "PepSourceOfFunds.paragraph.pepNotRelatedByEmployment": "These questions are applicable when you pep status is not related to your employment.",
    "FormSelect.label.employmentStatus": "Choose your employment status:",
    "FormSelect.label.industryType": "Describe the type of industry:",
    "FormSelect.label.yearlyAmount": "How much cryptocurrency do you anticipate buying per year using our service?",
    "FormSelect.label.potMainPurpose": "Describe the purpose of your transactions.",
    "FormSelect.label.originOfFunds": "Describe the origin of the financial resources you have used and will be using to conduct transactions with us:",
    "Checkbox.text.pepSofTnc": "I declare that the information I have provided on the Source of Funds Questionnaire is true and complete. I also agree to provide supporting documents as proof of funds upon request by Banxa.",
    "PageSubTitle.paragraph.purposeOfTransactionInstructions": "Before we process your order, we’re legally obligated to collect the additional information below.",
    "PageTitle.headline.purposeOfTransactionHeader": "Purpose of transaction.",
    "Checkbox.text.purposeOfTransactionTnc": "I confirm that all funds used to trade originate from legal activities.",
    "BodyCopy.paragraph.selfieInstructions": "Ensure that we can clearly see your face in the selfie, no hats or sunglasses please!','Ensure that your document is legible.','The photo needs to have been taken today.",
    "PageSubTitle.paragraph.selfieInstructions": "We need you to take a clear selfie of yourself holding your form of identification.",
    "PageTitle.headline.selfiePageHeader": "One last thing...",
    "FileUploadField.label.selfieDocument": "Upload your selfie",
    "ButtonBase.label.selfieClear": "My selfie is clear and legible",
    "PageTitle.headline.sourceOfFundsPageHeader": "Source of funds questionnaire.",
    "FormSelect.label.beneficiaries": "Besides yourself, are there any other individuals \/ beneficiaries who will benefit or have access to this account?",
    "PageTitle.headline.idvPageHeader": "Identity Confirmation",
    "PageSubTitle.paragraph.idvInstructions": "We've noticed that this is your first time transacting with us. You will need to complete a quick one-off identity verification.",
    "ActionModal.title.sumsubError": "Oops there has been an issue with your document",
    "ActionModal.title.plaidFailStep": "You have failed a verification step",
    "ActionModal.primaryBtn.plaidFailStep": "Restart my session",
    "ActionModal.body.plaidFailStep": "During your identity verification you failed a step missing to try again, your session will be refreshed.",
    "ActionModal.title.plaidCloseSession": "You have closed your verification session",
    "ActionModal.primaryBtn.plaidCloseSession": "Restart my session",
    "ActionModal.body.plaidCloseSession": "During your identity verification you closed your session, your session will be refreshed.",
    "PlaidId.error.finalRejection": "Your Banxa account has temporarily been placed on hold. Please reach out to {supportEmail} - where you can connect with our support team.",
    "ButtonBase.label.transferMade": "I have made the transfer",
    "PageTitle.headline.dcinteracHeader": "Paying using Interac e-Transfer",
    "PageSubTitle.paragraph.dcinteracInstructions": "Login to your bank account and make a {amount} {currency} Interac e-Transfer payment using the following details:",
    "PageSubTitle.paragraph.dcInteracSellInstructions": "Please wait while we are registering your deposit details.",
    "Directcredit.paragraph.formContent": "Enter the details of the bank account where you'd like us to deposit your funds. Make sure the account is a personal account in your name (no business accounts).",
    "Directcredit.ul.list": "Ensure your details are correct to avoid delays.",
    "FormInputField.label.bsb": "BSB Number*",
    "FormInputField.placeholder.bsb": "Enter BSB number",
    "FormInputField.error.accountNumberFieldErrorMsg": "Please enter a valid account number",
    "FormInputField.error.bsbErrorMsg": "Please enter a valid BSB number",
    "FormInput.error.minLengthErrorMsg": "The field must be at least {min} characters long",
    "Snackbar.error.invalidBankDetails": "Invalid Bank Details",
    "Snackbar.error.genericMessage": "Something went wrong.",
    "ExistingDCBankDetailsForm.label.headline": "Use Existing Bank Account",
    "TwoColumnTable.label.bsb": "BSB",
    "TwoColumnTable.label.billerName": "Send Interac e-Transfer to:",
    "TwoColumnTable.label.billerCode": "Use the following for the Security Question:",
    "TwoColumnTable.label.billingReference": "Use the following for the Security Answer:",
    "BodyCopy.paragraph.ftAchSellInstructions": "Click \"Connect\" button to connect your account with Plaid so we can deposit to you through ACH.",
    "BodyCopy.paragraph.ftAchSellList": "Use your personal account, not a company account.",
    "ButtonBase.label.idealPayWith": "Proceed to pay with {selectedBank}",
    "ideal.ul.list": "Payment must be sent from a personal bank account in your name.",
    "FormSelect.label.idealBicSelect": "Select a bank",
    "Checkbox.text.tnc": "I have read and agree to the {termOfUse}{privacyPolicy}{biometricPolicy}*",
    "Checkbox.text.termsAndCondition": "Terms and Conditions",
    "Checkbox.text.privacyPolicy": "Privacy and Cookies Policy",
    "NppForm.label.headline": "Paying using PayID",
    "NppForm.label.body": "Please deposit {amount} using the details exactly as they are seen below, we require these details to avoid delays in processing your order.",
    "TwoColumnTable.label.headline": "Transfer details",
    "TwoColumnTable.label.payIdEmail": "PayID email address",
    "TwoColumnTable.label.paymentDescription": "Payment description",
    "PoliForm.label.headline": "Pay using POLi",
    "PoliForm.label.subHeadline": "Before we progress with your payment we wanted to give you a heads up about the things that could slow down your order.",
    "PoliForm.ul.list": "Payment must be made from a personal bank account in your name.",
    "PoliForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "PoliForm.ul.list3": "To make payment, you will be redirected to Australia Post’s POLi platform to securely log into your bank account and confirm payment.",
    "PrimetrustForm.label.pleaseDeposit": "Please deposit {amountPaid} into the following bank account with the {referenceNumber} in the deposit reference field.",
    "PrimetrustForm.label.referenceNumber": "reference number",
    "PrimetrustForm.label.headline": "Transfer using Wire Transfer",
    "TwoColumnTable.label.routingNumber": "Routing number",
    "TwoColumnTable.label.benificiaryAddress": "Beneficiary Address",
    "TwoColumnTable.label.creditTo": "Credit to",
    "ManualpaymentForm.label.headline": "Paying Using Turkish Domestic Bank Transfer",
    "ManualpaymentForm.label.pleaseDeposit": "Please deposit {amountPaid} into the following bank account with the {referenceNumber} in the deposit reference field.",
    "TwoColumnTable.label.recipientName": "Receipient name",
    "TwoColumnTable.label.explaination": "Explaination",
    "MonoovapayidForm.label.cbaCalloutLabel": "Important Note",
    "MonoovapayidForm.label.cbaCallout": "Commonwealth Bank has strict controls around sending money for crypto purposes and may not approve PayID payments; consider using another bank for a smoother transaction.",
    "MonoovapayidForm.label.headline": "Paying using PayID",
    "MonoovapayidForm.label.pleaseDeposit": "Please deposit {amountValue} using the details exactly as they are seen below, we require these details to avoid delays in processing your order.",
    "ActionModal.title.applePaySessionError": "Oops! There was an issue with your apple pay session. Please try again.",
    "ActionModal.title.applePayPaymentFailedError": "Your apple payment has failed. Please try again.",
    "FormInput.error.validateWalletAddressErrorMsg": "The wallet address is invalid",
    "FormInput.error.validateFiatAmountErrorMsg": "{fiatType} orders must be between {min} and {max}",
    "FormInput.error.validateCoinAmountErrorMsg": "Please enter an amount greater or equal {minCoin}.",
    "FormInput.error.blockchainValidationErrorMsg": "No blockchains found by wallet",
    "FormInput.error.addressValidationErrorMsg": "Please enter a valid residential address",
    "FormInput.error.dobValidationErrorMsg": "Please enter a valid date as",
    "FormInput.error.ssnValidationErrorMsg": "Please enter a valid Social Security Number",
    "FormInput.error.ibanValidationErrorMsg": "Please enter a valid IBAN number",
    "FormInput.error.walletAddressTagValidationErrorMsg": "Enter a valid wallet {name}",
    "FormInput.error.curpValidationErrorMsg": "Please enter a valid CURP number",
    "FormInput.error.cpfValidationErrorMsg": "Please enter a valid CPF number",
    "FormInput.error.saidValidationErrorMsg": "Please enter a valid SAID number",
    "PageTitle.headline.taxIdHeader": "Social Security Number",
    "PageSubTitle.paragraph.taxIdInstructions": "Enter your social security number to complete your transaction",
    "FormInputField.label.ssn": "Social Security Number *",
    "ActionModal.title.sortFieldErrorMsg": "Please enter a valid sort code",
    "FormInput.error.ccValidationErrorMsg": "Please enter a valid CC number",
    "FormInput.error.dniValidationErrorMsg": "Please enter a valid DNI number\n",
    "FormInput.error.ticValidationErrorMsg": "Please enter a valid Thai National ID card number",
    "FormInput.error.ciValidationErrorMsg": "Please enter a valid CI number",
    "DlocalarioForm.label.headline": "Paying using IO",
    "DlocalarioForm.label.body": "Before we progress with your payment we wanted to give you a heads up about the things that could slow down your order.",
    "DlocalarioForm.ul.list": "Payment must be made from an account that is linked to your DNI.",
    "DlocalarioForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "DlocalarioForm.ul.list3": "To make payment, you will be redirected to a page where you can follow the instructions to complete the transaction.",
    "DlocalclkhForm.label.headline": "Paying using Khipu",
    "DlocalclkhForm.label.body": "Before we progress with your payment we wanted to give you a heads up about the things that could slow down your order.",
    "DlocalclkhForm.ul.list": "Payment must be made from an account that is linked to your CI.",
    "DlocalclkhForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "DlocalclkhForm.ul.list3": "To make payment, you will be redirected to a page where you can follow the instructions to complete the transaction.",
    "Dlocal.error.4015": "We can not proceed with the payment, something went wrong.",
    "DlocalcopseForm.label.headline": "Paying using PSE",
    "DlocalcopseForm.label.body": "Before we progress with your payment we wanted to give you a heads up about the things that could slow down your order.",
    "DlocalcopseForm.ul.list": "Payment must be made from an account that is linked to your CC.",
    "DlocalcopseForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "DlocalcopseForm.ul.list3": "To make payment, you will be redirected to your bank. You will need to authorise the payment to complete the transaction.",
    "DlocalpixForm.label.headline": "Paying using PIX",
    "DlocalpixForm.label.body": "Before we progress with your payment we wanted to give you a heads up about the things that could slow down your order.",
    "DlocalpixForm.ul.list": "Payment must be made from an account that is linked to your CPF.",
    "DlocalpixForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "DlocalpixForm.ul.list3": "To make payment, you will be redirected to a page with the PIX payment details. You will need to make the payment from your PIX-enabled app.",
    "DlocalspeiForm.label.headline": "Paying using SPEI",
    "DlocalspeiForm.label.body": "Before we progress with your payment we wanted to give you a heads up about the things that could slow down your order.",
    "DlocalspeiForm.ul.list": "Payment must be made from an account that is linked to your CURP.",
    "DlocalspeiForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "DlocalspeiForm.ul.list3": "To make payment, you will be redirected to a page with the SPEI payment details. You will need to make the payment from your SPEI-enabled app.",
    "DlocalzaioForm.label.headline": "Paying using ZAIO",
    "DlocalzaioForm.label.body": "Before we finalise your payment, we wanted to give you a heads up about a few things that could slow down your order.",
    "DlocalzaioForm.ul.list": "The payment must be made from an account that is only in your name (not joint, not corporate) otherwise we will not fulfil the order.",
    "DlocalzaioForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once payment arrives in our account. Normally this should take 5 minutes.",
    "DlocalzaioForm.ul.list3": "To make payment, you will be required to login to your online banking. You will need to make payment from your bank account.",
    "DlocalzaioForm.ul.list4": "Ensure that you follow the instructions exactly.",
    "EnumisbankForm.label.headline": "Transfer using Faster Payments",
    "EnumisbankForm.label.pleaseDeposit": "Please deposit {amountPaid} into the following bank account with the {referenceNumber} in the deposit reference field.",
    "DlocalthqrForm.label.headline": "Paying using Wallet",
    "DlocalthqrForm.label.body": "Before we progress with your payment we wanted to give you a heads up         about the things that could slow down your order.",
    "DlocalthqrForm.ul.list": "Payment must be made from an account that is linked to your Thai National ID card.",
    "DlocalthqrForm.ul.list2": "Your coins will be purchased immediately after you have made payment. However, they will only be sent once your payment arrives in our account which may take up to 1 business day.",
    "DlocalthqrForm.ul.list3": "To make payment, you will be redirected to a page where you can follow the instructions to complete the transaction.",
    "Directcredit.ul.list2": "Incorrect bank details will incur a $27.50 administration fee which will be deducted from your order.",
    "ideal.ul.list2": "Once successful, you will receive your order in 48 hours.",
    "LanguageInput.label.selectLanguage": "Select a language",
    "Checkbox.text.zeroHash": "I agree to the {zeroHashLink}, and I have read and understand the Zero Hash {zerohashLink} and {zendDeskLink}. I understand that the value of any cryptocurrency, including digital assets pegged to fiat currency, commodities, or any other asset, may go to zero.",
    "Checkbox.text.zeroHashUserAgreement": "Zero Hash and Zero Hash Liquidity Services User Agreement",
    "Checkbox.text.zeroHashPrivacyPolicy": "Privacy Policy",
    "Checkbox.text.regulatoryDisclosure": "Regulatory Disclosures",
    "SummaryHeader.label.headline": "Hip-hip",
    "SummaryHeader.label.subHeadline": "Hooray!",
    "SummaryHeader.label.body": "It looks like you've done everything you need to. We'll work on fulfilling your order and email you as your order progresses.",
    "SummaryTable.label.title": "Order #",
    "SummaryTable.ul.list": "Approx time to fulfil",
    "SummaryTable.ul.list2": "1 to 60 minutes",
    "SummaryTable.ul.list3": "Payment amount",
    "SummaryTable.ul.list4": "Amount to receive",
    "SummaryTable.ul.list5": "Wallet address",
    "SummaryTable.ul.list6": "Selected Blockchain",
    "SummaryTable.ul.list7": "Payment method",
    "SummaryTable.ul.list8": "Deposit method",
    "SummaryTable.ul.list9": "Network Fee",
    "SummaryTable.ul.list10": "Processing Fee",
    "SummaryTable.ul.list11": "Commission fees",
    "ButtonBase.label.submitting": "Submitting....",
    "ButtonSecondary.label.submitting": "Submitting....",
    "FormInputField.placeholder.lastName": "Enter your last name",
    "IdentityQueueNext.p.upNext": "Up next:",
    "IdentityQueueStepCounter.p.stepCount": "Step { currentStep } of { totalSteps }",
    "MobileField.error.mobileNumberErrorMsgForCountry": "Please enter the mobile number that belongs to",
    "OrderRecoveryModal.label.subhead": "Before we continue",
    "OrderRecoveryModal.label.headline": "We need to get the latest prices",
    "OrderRecoveryModal.label.dofpBody": "Your cooling off period is now complete. Crypto markets move fast. To ensure we're providing the right amount of crypto to you we've re-quoted your order with the most recent prices.",
    "OrderRecoveryModal.label.body": "Crypto markets move fast. To ensure we're providing the right amount of crypto to you we've re-quoted your order with the most recent prices.",
    "OrderRecoveryModal.label.spend": "You spend",
    "OrderRecoveryModal.label.receive": "You receive",
    "ButtonBase.label.confirmRecovery": "Confirm and continue",
    "ButtonBase.label.declineRecovery": "I'd like to change my order",
    "PageTitle.headline.PepSofDocumentUploadPageHeader": "Pep Source of funds document upload",
    "PageSubTitle.paragraph.sofDocumentUploadInstructions": "Please upload the following documents to verify your source of funds.",
    "PageSubTitle.paragraph.sofDocumentUploadInstructions2": "Past 3 month’s bank statement.','Recent payslips.'",
    "ButtonBase.label.sofDocument": "My document is clear and legible",
    "PlopenbankForm.label.clickConnect": "Click \"Connect\" to securely link your bank account using Plaid.",
    "PlopenbankForm.error.errorPayment": "Encountered an error while completing the payment on the Plaid interface. Please try again.",
    "PlopenbankForm.error.getOrderId": "Encountered an issue while retrieving order details.",
    "PlaidRedirectView.heading.continue": "Continue...",
    "PlaidRedirectView.paragraph.instruction": "You are almost done. Please wait.",
    "PlaidRedirectAchView.paragraph.instruction": "You are almost done. Please go back to the app and close this window.",
    "Sumsub.error.finalRejection": "Your Banxa account has been temporarily suspended. Please contact our support team at {supportEmail}.",
    "Checkbox.text.biometricPolicy": "Biometric Privacy Disclosure and Consent",
    "FormInput.error.addressLine1ValidationErrorMsg": "Unfortunately, we can't accept a PO Box as an address.",
    "FormInput.error.stateErrorMsg": "Please enter a valid state, {state} is an unsupported state",
    "SummaryTable.additionalInfo": "^Further information may be required to process an order which may cause a delay from the approximate timeframe stipulated above. If this occurs we will email you to advise what information is required before proceeding with your order.",
    "SummaryTable.additionalInfo2": "Important: Banxa will only ever contact you via an official banxa.com email address. If you are ever in doubt please email us at {supportEmail}.",
    "CcSelfieForm.label.headline": "Credit\/Debit Card Verification",
    "CcSelfieForm.label.body": "Please provide a selfie of you holding the card used for payment and a note stating {entity} and today's date.",
    "CcSelfieForm.ul.list": "Your face is clearly visible",
    "CcSelfieForm.ul.list2": "Name and expiration date are visible",
    "CcSelfieForm.ul.list3": "First 4 and last 4 digits are visible",
    "CcSelfieForm.ul.list4": "Middle 8 digits must be covered (Example: 1337 **** **** 1337)",
    "CcSelfieForm.ul.list5": "Ensure that we can clearly see your face in the selfie, no hats or sunglasses please!",
    "CcSelfieForm.ul.list6": "Ensure that your document is legible.",
    "CcSelfieForm.ul.list7": "The photo needs to have been taken today.",
    "PaymentInput.emptyState.label": "There are no supported payment methods for your selected currency",
    "ConfirmDetailForm.label.headline": "Confirm your details",
    "ConfirmDetailForm.label.body": "For compliance reasons, we need to confirm that your details are still correct. Please review the information below and confirm if they're still correct.",
    "ButtonSecondary.label.editDetails": "Edit my details",
    "ButtonSecondary.label.correctDetails": "The details above are correct",
    "TwoColumnTable.label.fullName": "Full Name",
    "TwoColumnTable.label.dob": "Date of Birth",
    "TwoColumnTable.label.address": "Address",
    "TwoColumnTable.label.postCode": "Post Code",
    "TwoColumnTable.label.country": "Country",
    "UpdateDetailForm.label.headline": "Verify Personal Details",
    "WalletAddressVerification.label.headline": "Confirm You Own the Wallet",
    "WalletAddressVerification.label.body": "Please verify you own this Wallet.",
    "WalletAddressVerification.ul.list1Header": "Please Upload An Image of Your Wallet",
    "WalletAddressVerification.ul.list1a": "Sign-in to your Wallet.",
    "WalletAddressVerification.ul.list1b": "We will remember this Wallet the next time you place an order.",
    "WalletAddressVerification.ul.list1c": "Take an image that shows your Wallet address and the whole Wallet screen.",
    "WalletAddressVerification.ul.list2Header": "Guidelines and tips",
    "WalletAddressVerification.ul.list2a": "Ensure the Wallet Address is clearly visible",
    "WalletAddressVerification.ul.list2b": "Capture the entire screen",
    "WalletAddressVerification.ul.list2c": "Do not cover any information on the screen",
    "WalletAddressVerification.ul.list2d": "Further information is provided {insertLink}",
    "WalletAddressVerification.ul.here": "here",
    "FileUploadField.label.walletImage": "Please upload your Wallet image here",
    "FormInputField.label.walletAddress": "Wallet Address",
    "FormInputField.label.walletProvider": "Who is your Wallet provider?",
    "FormInputField.placeholder.walletProvider": "Enter your Wallet provider",
    "FormInputField.label.walletAddressTag": "Wallet Address Tag",
    "ButtonBase.label.walletImage": "Submit wallet screenshot",
    "OrderSummaryView.label.defaultSubtitle": "It looks like you've done everything you need to. We'll work on fulfilling your order and email you as your order progresses.",
    "OrderSummaryView.label.paymentPending": "We’re still verifying your payment details. Once verified, we’ll work on fulfilling your order and email you as it progresses.",
    "OrderSummaryView.label.identityPending": "We’re still verifying your identity details. Once verified, we’ll work on fulfilling your order and email you as it progresses.",
    "OrderSummaryView.label.paymentAndIdentityPending": "We’re still verifying your payment and identity details. Once verified, we’ll work on fulfilling your order and email you as it progresses.",
    "OrderSummaryView.label.fullfilmentPending": "We’ve got all we need. We’ll work on fulfilling your order and email you as your order progresses.",
    "OrderSummaryView.label.fulfillmentCompleted": "That’s a wrap! Your coins have been fulfilled and will be in your wallet shortly.",
    "IdentityQueueNext.p.payment": "Payment",
    "IdentityQueueNext.p.orderStatus": "Order status",
    "IdentityQueueStore.name.upNextBillingDetails": "Billing Details",
    "IdentityQueueStore.name.upNextProofOfAddress": "Proof of Address",
    "IdentityQueueStore.name.upNextSof": "Source of Funds",
    "IdentityQueueStore.name.upNextPurposeOfTransaction": "Purpose of Transaction",
    "IdentityQueueStore.name.upNextSofDocUpload": "Source of Funds Document Upload",
    "IdentityQueueStore.name.upNextDocUpload": "Document Upload",
    "IdentityQueueStore.name.upNextDocNumber": "Document Number",
    "IdentityQueueStore.name.upNextSelfieDocUpload": "Selfie Document Upload",
    "IdentityQueueStore.name.upNextOccupation": "Occupation",
    "IdentityQueueStore.name.upNextPepSof": "PEP Source of Funds",
    "IdentityQueueStore.name.upNextPepSofDoc": "PEP Source of Funds Document Upload",
    "IdentityQueueStore.name.upNextSSN": "SSN Tax ID",
    "IdentityQueueStore.name.upNextDlocalCpf": "Your Cadastro de Pessoas Físicas",
    "IdentityQueueStore.name.upNextDlocalSaid": "Your South African Identity Card",
    "IdentityQueueStore.name.upNextDlocalCurp": "Your Mexican Identity Card",
    "IdentityQueueStore.name.upNextDlocalDni": "Your Documento Nacional de Identidad",
    "IdentityQueueStore.name.upNextDlocalCc": "Your Cédula de Ciudadanía",
    "IdentityQueueStore.name.upNextDlocalCi": "Your Cédula de identidad",
    "IdentityQueueStore.name.upNextDlocalTic": "Your Thai Identity Card",
    "IdentityQueueStore.name.upNextWalletOwnership": "Wallet Ownership",
    "OrderSummaryView.label.startNewOrder": "Start a new order",
    "merchantSettings.label.returnMessage": "Return to",
    "ArchaxFooter.text.disclaimer": "Approved by {archaxLink} on 7th October 2023",
    "MetamaskdepositForm.label.connectYourWallet": "Connect your wallet with {connectWallet} button below. Please ensure that your wallet is on the correct network.",
    "MetamaskdepositForm.label.connectWallet": "Connect to Metamask",
    "MetamaskdepositForm.label.completeTransaction": "To complete your transactions, click the {coinAmount} button below. Please ensure that your wallet is on the correct network.",
    "MetamaskdepositForm.label.sell": "Sell",
    "MetamaskdepositForm.label.insufficientBalance": "Insufficient Balance",
    "MetamaskdepositForm.label.amountToSell": "Amount to sell",
    "MetamaskdepositForm.label.walletConnected": "Wallet connected",
    "MetamaskdepositForm.label.processingTransaction": "Waiting for transaction",
    "Metamaskdeposit.WarningAlert.label.title": "Don't overspend",
    "Metamaskdeposit.WarningAlert.label.body1": "You've already queued a transaction for this order. Go to your Metamask wallet. You'll be asked to approve or reject the transaction from your wallet.",
    "Metamaskdeposit.WarningAlert.label.body2": "If you've already confirmed the transaction, please wait on this page for an update.",
    "CoinDepositForm.error.sellWalletType": "Sell wallet type is not supported",
    "MetamaskdepositForm.checkbox.rejected": "I have already rejected the previous transaction",
    "MetamaskdepositForm.label.incorrectNetwork": "Incorrect network",
    "NativedepositForm.label.completeTransaction": "To complete your transactions, click the {backToMerchant} button below. You will be redirected back to the {merchant} wallet to deposit your coins.",
    "NativedepositForm.label.redirect": "Go to {merchant} wallet",
    "PaymentForm.description.primercc": "Your final quote will depend on payment method fees and network fees. We'll update your quote automatically.",
    "ButtonBase.label.submitPayment": "Submit payment",
    "Checkbox.text.saveCardFaster": "Save this card for future purchases",
    "PrimerSavedCardForm.error.notifyCustomer": "Something went wrong. Please refresh the page and try again.",
    "PrimerccForm.error.paymentFailed": "Payment failed. Please try again.",
    "PrimerSavedCardForm.error.cvvRequired": "CVV number is required",
    "PrimerSavedCardForm.error.cvvIncomplete": "CVV number is incomplete",
    "PaymentForm.title.primercc": "Complete your payment",
    "ButtonBase.label.useNewCard": "Use a new card",
    "DofpForm.headline.header": "Please complete the following Appropriateness Questionnaire",
    "DofpOrderForm.form.message": "Your order amount will be requoted at the end of your 24-hour cooling off period.",
    "DofpForm.body.body1": "As part of the UK Consumer Protection Rules, we will be asking you a series of questions to understand whether the cryptoasset investment is appropriate for you.",
    "DofpForm.body.body2": "{firstName}, this is a high-risk investment. How would you feel if you lost the money you’re about to invest? {riskSummaryLink}",
    "DofpForm.label.question1": "Question 1",
    "DofpForm.label.submitMyAnswers": "Submit my answers",
    "DofpForm.label.question2": "Question 2",
    "DofpForm.label.question3": "Question 3",
    "DofpForm.label.question4": "Question 4",
    "DofpForm.label.question5": "Question 5",
    "DofpForm.label.question6": "Question 6",
    "DofpForm.label.question7": "Question 7",
    "DofpForm.label.question8": "Question 8",
    "DofpForm.label.question9": "Question 9",
    "DofpForm.label.question10": "Question 10",
    "DofpBuyForm1.question.question1": "What happens when you buy cryptoassets using Banxa?",
    "DofpBuyForm1.question.question2": "What is a possible outcome of investing in a cryptoasset?",
    "DofpBuyForm1.question.question3": "Which of the following is TRUE regarding the benefits of diversification in relation to investments.",
    "DofpBuyForm1.question.question4": "What factors might make selling your cryptoassets a challenge?",
    "DofpBuyForm1.question.question5": "Which of the following statements is TRUE about the risks of investing in cryptoassets?",
    "DofpBuyForm1.question.question6": "Who owns the cryptoassests that you buy through Banxa? ",
    "DofpBuyForm1.question.question7": "If Banxa becomes insolvent what would happen to your cryptoassets?",
    "DofpBuyForm1.question.question8": "Why are cryptoassets considered risky when compared to other traditional investments?",
    "DofpBuyForm1.question.question9": "If you lose your money, will the Financial Ombudsman Service (FOS) or Financial Service Compensation Scheme (FSCS) protect you against your cryptoasset losses?",
    "DofpBuyForm1.question.question10": "Which of the following is TRUE regarding the risks of losing the money you invest in cryptoassets?",
    "DofpBuyForm1.answer.question1A": "I own and am responsible for safeguarding my cryptoassets. Banxa only facilitates the purchase.",
    "DofpBuyForm1.answer.question1B": "Banxa is responsible for safeguarding my cryptoassets.",
    "DofpBuyForm1.answer.question1C": "Banxa manages my cryptoassets for me.",
    "DofpBuyForm1.answer.question2A": "Guaranteed returns regardless of market conditions",
    "DofpBuyForm1.answer.question2B": "Potential partial or complete loss of the invested funds",
    "DofpBuyForm1.answer.question2C": "Assured protection against any market downturns",
    "DofpBuyForm1.answer.question3A": "Putting all my money into a single type of investment, such as cryptoassets, has no risks.",
    "DofpBuyForm1.answer.question3B": "There are no benefits of spreading my money across different investments.",
    "DofpBuyForm1.answer.question3C": "The Financial Conduct Authority (FCA)'s recommendation is not to invest more than 10% of my money in high-risk investments, such as cryptoassets.",
    "DofpBuyForm1.answer.question4A": "The cryptoassets's underlying technology may face technical problems.",
    "DofpBuyForm1.answer.question4B": "The trading platform that I use has an operational outage.",
    "DofpBuyForm1.answer.question4C": "There is no demand for the cryptoasset in the market at that time.",
    "DofpBuyForm1.answer.question4D": "All of the above.",
    "DofpBuyForm1.answer.question5A": "The complex nature of cryptoasset investments means that investors are guaranteed high returns on their investments.",
    "DofpBuyForm1.answer.question5B": "I must conduct my own research to understand the risks associated with different cryptoassets before investing. ",
    "DofpBuyForm1.answer.question5C": "There is no risk associated with a cryptoasset business becoming insolvent or otherwise failing.",
    "DofpBuyForm1.answer.question6A": "Banxa retains ownership of the cryptoassets.",
    "DofpBuyForm1.answer.question6B": "I own the cryptoassets, and there is a risk I could lose all the money I have invested.",
    "DofpBuyForm1.answer.question6C": "The government owns the cryptoassets, and will cover any losses I suffer.",
    "DofpBuyForm1.answer.question7A": "I would need to talk to the administrator appointed to oversee Banxa's insolvency.",
    "DofpBuyForm1.answer.question7B": "I would not be affected as Banxa does not hold my cryptoassets.",
    "DofpBuyForm1.answer.question7C": "I would need to talk to my bank to clawback the money I paid for the cryptoassets.",
    "DofpBuyForm1.answer.question8A": "Cryptoassets are not risky as they are stable and offer guaranteed long term returns.",
    "DofpBuyForm1.answer.question8B": "Cryptoassets are volatile and have unpredictable price movements.",
    "DofpBuyForm1.answer.question8C": "The only risk is during a global financial crisis.",
    "DofpBuyForm1.answer.question9A": "Yes, my money is guaranteed.",
    "DofpBuyForm1.answer.question9B": "No, only a portion of my money is guaranteed.",
    "DofpBuyForm1.answer.question9C": "No, unlike a bank, my money is not protected.",
    "DofpBuyForm1.answer.question10A": "Cryptoassests have the same risks as traditional investments.",
    "DofpBuyForm1.answer.question10B": "Cryptoassets are simple and safe in nature and there is no risk of losing any money.",
    "DofpBuyForm1.answer.question10C": "Cryptoassets can be volatile, and there is a risk of losing money due to market downturn, technical vulnerabilities and financial crime.",
    "DofpBuyForm2.question.question1": "What is your understanding of Banxa's services?",
    "DofpBuyForm2.question.question2": "Which of the following statements is TRUE regarding the performance of cryptoasset investments?",
    "DofpBuyForm2.question.question3": "What is the potential outcome if a cryptoasset firm which takes custody of my cryptoassets becomes insolvent or experiences operational failures?",
    "DofpBuyForm2.question.question4": "Which of the following statements is TRUE regarding retail consumer protections provided for cryptoasset investments. ",
    "DofpBuyForm2.question.question5": "Why is protecting the private key crucial for cryptoasset holders?",
    "DofpBuyForm2.question.question6": "What aspect of cryptoassets adds complexity to their risk assessment?",
    "DofpBuyForm2.question.question7": "What factor distinguishes the risk profile of investing in cryptoassets from traditional securities?",
    "DofpBuyForm2.question.question8": "Why is it generally advised for retail clients to limit their investment in cryptoassets to around 10% of their net assets?",
    "DofpBuyForm2.question.question9": "Which scenario is an example of self-custodial ownership of cryptoassets?",
    "DofpBuyForm2.question.question10": "Why should investors be cautious when dealing with firms offering cryptoassets not registered with the Financial Conduct Authority (FCA)?",
    "DofpBuyForm2.answer.question1A": "I can store my cryptoassets using Banxa's custodial wallet service.",
    "DofpBuyForm2.answer.question1B": "I can exchange fiat currency for certain cryptoassets or exchange my cryptoassets for fiat currency using Banxa.",
    "DofpBuyForm2.answer.question1C": "I can transfer my cryptoassets to third parties using Banxa.",
    "DofpBuyForm2.answer.question2A": "I can rely on the past performance of cryptoassets such as Bitcoin and Ethereum to predict its future performance.",
    "DofpBuyForm2.answer.question2B": "There is a risk of losing money or cryptoassets due to cyber-attacks or cryptoasset firm failures.",
    "DofpBuyForm2.answer.question2C": "I am guaranteed high returns on cryptoasset investments given that they are complex investments.",
    "DofpBuyForm2.answer.above": "above",
    "DofpBuyForm2.answer.question3A": "My investment is fully protected and guaranteed by the cryptoasset firm.",
    "DofpBuyForm2.answer.question3B": "My investment may be at risk, and recovery could be difficult.",
    "DofpBuyForm2.answer.question3C": "Insolvency and operational failures do not affect cryptoasset firms.",
    "DofpBuyForm2.answer.question4A": "Cryptoassets are largely unregulated and government bodies, such as the Financial Ombudsman Service (FOS) or Financial Service Compensation Scheme (FSCS), do not provide protection against poor investment performance or outcomes of cryptoasset investments.",
    "DofpBuyForm2.answer.question4B": "The FCA is able to provide a completely risk-free investment environment for cryptoassets.",
    "DofpBuyForm2.answer.question4C": "The Financial Ombudsman Service (FOS) will be able to consider complaints related my cryptoasset investments.",
    "DofpBuyForm2.answer.question5A": "Loss or compromise of the private key may reduce transaction speed, impacting the timely purchase and sale of cryptoassets.",
    "DofpBuyForm2.answer.question5B": "Loss or compromise of the private key may result in the unauthorised access and loss of cryptoassets.",
    "DofpBuyForm2.answer.question5C": "Loss or compromise of the private key may impact the validation of transactions on the blockchain and the security of my cryptoassets.",
    "DofpBuyForm2.answer.question6A": "Clear and consistent valuation methodologies",
    "DofpBuyForm2.answer.question6B": "Decentralised nature of blockchain networks",
    "DofpBuyForm2.answer.question6C": "Easily predictable market trends",
    "DofpBuyForm2.answer.question7A": "Highly regulated trading environments in cryptoasset markets",
    "DofpBuyForm2.answer.question7B": "Lower volatility and higher predictability in cryptoasset prices",
    "DofpBuyForm2.answer.question7C": "Greater uncertainty due to technological, regulatory, and market risks",
    "DofpBuyForm2.answer.question8A": "To ensure maximum returns from high risk assets",
    "DofpBuyForm2.answer.question8B": "To diversify and safeguard against total portfolio loss due to the volatile nature of cryptoassets",
    "DofpBuyForm2.answer.question8C": "To ensure that the portfolio has a high proportion of high risk or speculative assets",
    "DofpBuyForm2.answer.question9A": "Holding assets on an exchange without control over private keys",
    "DofpBuyForm2.answer.question9B": "Registering assets on a public blockchain ledger",
    "DofpBuyForm2.answer.question9C": "Storing assets in a non-custodial hardware wallet",
    "DofpBuyForm2.answer.question10A": "The firm provides better investment opportunities with minimal risks",
    "DofpBuyForm2.answer.question10B": "The firm might not adhere to standards of the Financial Conduct Authority (FCA), potentially risking investor funds",
    "DofpBuyForm2.answer.question10C": "The firm ensures high returns without any market volatility",
    "DofpBuyForm3.question.question1": "Which of the following services does Banxa offer?",
    "DofpBuyForm3.question.question2": "How might security risks on cryptoasset firms such as cyber-attacks affect your investments in cryptoassets?",
    "DofpBuyForm3.question.question3": "What role does diversification play in managing risks in a cryptoasset portfolio?",
    "DofpBuyForm3.question.question4": "What can you expect if something goes wrong with your cryptoasset investment with Banxa?",
    "DofpBuyForm3.question.question5": "What is the primary risk of using unlicensed or unregistered online cryptoasset platforms to invest in cryptoassets?",
    "DofpBuyForm3.question.question6": "Which of the following poses the lowest risk to the ownership and rights to your cryptoassets?",
    "DofpBuyForm3.question.question7": "Why is it important to understand the complex nature of cryptoassets?",
    "DofpBuyForm3.question.question8": "Why is investing in cryptoassets not comparable to investing in traditional investments such as listed securities?",
    "DofpBuyForm3.question.question9": "How can the volatile nature of cryptoassets impact investor expectations?",
    "DofpBuyForm3.question.question10": "What is a possible consequence of market illiquidity or operational outages when selling a cryptoasset investment?",
    "DofpBuyForm3.answer.question1A": "I can store my cryptoassets using Banxa's custodial wallet service.",
    "DofpBuyForm3.answer.question1B": "I can transfer my cryptoassets to third parties using Banxa.",
    "DofpBuyForm3.answer.question1C": "I can exchange fiat currency for certain cryptoassets or exchange my cryptoassets for fiat currency using Banxa.",
    "DofpBuyForm3.answer.question2A": "Cyber-attacks have no impact on the security of cryptoasset investments.",
    "DofpBuyForm3.answer.question2B": "Cyber-attacks can lead to theft or loss of cryptoassets due to vulnerabilities.",
    "DofpBuyForm3.answer.question2C": "Cryptoassets are immune to cyber threats due to their decentralised nature.",
    "DofpBuyForm3.answer.question3A": "Diversification would not be necessary for cryptoasset portfolios since all cryptoassets have the same risk profile.",
    "DofpBuyForm3.answer.question3B": "Diversification can prevent all potential losses.",
    "DofpBuyForm3.answer.question3C": "Diversification can help reduce the risk of investing in a single type of investment.",
    "DofpBuyForm3.answer.question4A": "I will be fully protected by the Financial Services Compensation Scheme (FSCS).",
    "DofpBuyForm3.answer.question4B": "The Financial Ombudsman Service (FOS) will be able to consider my complaint.",
    "DofpBuyForm3.answer.question4C": "The Financial Services Compensation Scheme (FSCS) doesn’t protect against the cryptoasset investments offered by Banxa.",
    "DofpBuyForm3.answer.question5A": "There are no risks with using an unlicensed or unregistered cryptoasset firm.",
    "DofpBuyForm3.answer.question5B": "Unlicensed and unregistered cryptoassets firms may not abide by any regulatory rules to assist with protecting consumers.",
    "DofpBuyForm3.answer.question5C": "Using an unlicensed or unregistered cryptoassets firm may reduce the risk of hacking and theft, and loss of my cryptoassets.",
    "DofpBuyForm3.answer.question6A": "Storing my cryptoassets in a non-custodial wallet",
    "DofpBuyForm3.answer.question6B": "Storing my cryptoassets in a custodial wallet",
    "DofpBuyForm3.answer.question6C": "There are no risks to the ownership and rights to my cryptoassets as I am protected by Financial Conduct Authority (FCA)",
    "DofpBuyForm3.answer.question7A": "To allow for easy manipulation of market trends",
    "DofpBuyForm3.answer.question7B": "There is no importance as cryptoassets are not complex and easy to understand",
    "DofpBuyForm3.answer.question7C": "It helps in evaluating the risks associated with cryptoassets",
    "DofpBuyForm3.answer.question8A": "There is greater market stability",
    "DofpBuyForm3.answer.question8B": "There is centralised control over asset issuance",
    "DofpBuyForm3.answer.question8C": "The decentralised nature of the underlying technology",
    "DofpBuyForm3.answer.question9A": "It ensures predictable and consistent returns",
    "DofpBuyForm3.answer.question9B": "It guarantees rapid wealth accumulation",
    "DofpBuyForm3.answer.question9C": "That the value of the cryptoasset investment can fall as quickly as it can rise",
    "DofpBuyForm3.answer.question10A": "Smooth and uninterrupted sell transactions at any time",
    "DofpBuyForm3.answer.question10B": "Potential delays or failure in executing sell orders as desired",
    "DofpBuyForm3.answer.question10C": "Guaranteed high returns due to limited market access",
    "DofpSellForm1.question.question1": "Which of the following services does Banxa offer?",
    "DofpSellForm1.question.question2": "Which of the following is TRUE in relation to the performance of cryptoassets (with the exclusion of stablecoins)?",
    "DofpSellForm1.question.question3": "Which of the following is a risk when dealing with cryptoasset firms not registered with the Financial Conduct Authority (FCA)?",
    "DofpSellForm1.question.question4": "Which of the following is a risk when dealing with cryptoasset firms not registered with the Financial Conduct Authority (FCA)?",
    "DofpSellForm1.question.question5": "What is a potential impact of a large-scale market sell-off on the risk of selling cryptoassets?",
    "DofpSellForm1.question.question6": "Which of the following is TRUE in relation to diversifying your investments.",
    "DofpSellForm1.question.question7": "Which of the following is a risk associated with storing your cryptoassets in a custodial wallet compared to a non-custodial wallet?",
    "DofpSellForm1.question.question8": "What factor makes assessing risks in selling cryptoassets more difficult compared to traditional investments?",
    "DofpSellForm1.question.question9": "In the event of a cryptoasset firm becoming insolvent or not operational, which of the following statements is FALSE.",
    "DofpSellForm1.question.question10": "Why is it important for investors to understand the limitations of protection offered by Financial Ombudsman Service (FOS) or Financial Service Compensation Scheme (FSCS) in relation to cryptoassets?",
    "DofpSellForm1.answer.question1A": "I can store my cryptoassets using Banxa's custodial wallet service.",
    "DofpSellForm1.answer.question1B": "I can transfer my cryptoassets to third parties using Banxa.",
    "DofpSellForm1.answer.question1C": " I can exchange fiat currency for certain cryptoassets or exchange my cryptoassets for fiat currency using Banxa.",
    "DofpSellForm1.answer.question2A": "The performance of many cryptoassets can be highly volatile",
    "DofpSellForm1.answer.question2B": "The price of most cryptoassets do not fluctuate",
    "DofpSellForm1.answer.question2C": "I am unlikely to lose any money or cryptoassets as positive returns on most cryptoassets are guaranteed",
    "DofpSellForm1.answer.question3A": "The firm might not adhere to standards of the FCA, potentially risking investor funds or assets",
    "DofpSellForm1.answer.question3B": "The firm provides better returns on cryptoassets with minimal risks",
    "DofpSellForm1.answer.question3C": "The firm’s transaction speed may be lower if the firm is not registered with the FCA",
    "DofpSellForm1.answer.question4A": "The firm might not adhere to standards of the FCA, potentially risking investor funds or assets",
    "DofpSellForm1.answer.question4B": "The firm provides better returns on cryptoassets with minimal risks",
    "DofpSellForm1.answer.question4C": "The firm’s transaction speed may be lower if the firm is not registered with the FCA",
    "DofpSellForm1.answer.question5A": "A reduction in risk due to increased market stability",
    "DofpSellForm1.answer.question5B": "An increase in risk due to panic selling and price decline",
    "DofpSellForm1.answer.question5C": "There is no impact as market sell-offs do not affect cryptoassets.",
    "DofpSellForm1.answer.question6A": "Putting all your money into a single type of investment is risky.",
    "DofpSellForm1.answer.question6B": "A good rule of thumb is to invest more than 50% of your money in high-risk investments.",
    "DofpSellForm1.answer.question6C": "Spreading your money across different investments guarantees positive investment returns.",
    "DofpSellForm1.answer.question7A": "There is a risk of fraud, theft or mismanagement with the use of a custodial wallet",
    "DofpSellForm1.answer.question7B": "There is full control over private keys with the use of a custodial wallet",
    "DofpSellForm1.answer.question7C": "There is no risk as I am guaranteed retrieval through customer support",
    "DofpSellForm1.answer.question8A": "Predictable market behavior",
    "DofpSellForm1.answer.question8B": " Decentralised, complex and unregulated nature of cryptoassets",
    "DofpSellForm1.answer.question8C": "Limited technological innovation",
    "DofpSellForm1.answer.question9A": "There is a risk of unwanted delay and I may be unable to sell my cryptoassets at the desired time",
    "DofpSellForm1.answer.question9B": "There is a risk of losing my funds or assets",
    "DofpSellForm1.answer.question9C": "There are no associated risks as I am guaranteed that my cryptoassets can be easily sold at any given time.",
    "DofpSellForm1.answer.question10A": "To ensure guaranteed profits from the cryptoasset investments",
    "DofpSellForm1.answer.question10B": "To make informed decisions and manage expectations regarding lack of protection against investment outcomes or losses",
    "DofpSellForm1.answer.question10C": "To seek compensation for poor investment performance from regulatory bodies",
    "DofpSellForm2.question.question1": "Which of these services does Banxa offer to you?",
    "DofpSellForm2.question.question2": "Which of the following is a potential risk resulting from the operational failure of a cryptoasset business.",
    "DofpSellForm2.question.question3": "Which of the following can contribute to the risk of hacking and unauthorised access when selling your cryptoassets?",
    "DofpSellForm2.question.question4": "In the context of selling cryptoassets, what does the term 'liquidity risk' refer to?",
    "DofpSellForm2.question.question5": "What impact do external factors, such as global economic events, potentially have on the risks associated with selling cryptoassets (with the exclusion of stablecoins)?",
    "DofpSellForm2.question.question6": "Which of the following is a risk concerning the beneficial ownership of your cryptoassets?",
    "DofpSellForm2.question.question7": "Why is it important to understand the registration status of a cryptoasset business with the Financial Conduct Authority (FCA)?",
    "DofpSellForm2.question.question8": "When selling your cryptoassets, which of the following may result in the loss of money invested in the cryptoassets (with the exclusion of stablecoins)?",
    "DofpSellForm2.question.question9": "What of the following is TRUE in relation to cryptoassets when compared to mainstream investments?",
    "DofpSellForm2.question.question10": "To what extent does the Financial Ombudsman Service (FOS) protect investors engaged in cryptoasset investment activities?",
    "DofpSellForm2.answer.question1A": "You can exchange your fiat currency for certain cryptoassets or exchange your cryptoassets for fiat currency with Banxa.",
    "DofpSellForm2.answer.question1B": "Banxa provides you with a custodial wallet service to store your cryptoassets.",
    "DofpSellForm2.answer.question1C": "Banxa allows you to transfer your cryptoassets to third parties.",
    "DofpSellForm2.answer.question2A": "There is a risk that I may lose my funds or cryptoassets",
    "DofpSellForm2.answer.question2B": "There is a risk that I may not receive positive returns when selling my cryptoassets",
    "DofpSellForm2.answer.question2C": "There is a risk that my transaction fees may increase",
    "DofpSellForm2.answer.question3A": "Strong password protection",
    "DofpSellForm2.answer.question3B": "Using multi-factor authentication",
    "DofpSellForm2.answer.question3C": "Publicly sharing private keys",
    "DofpSellForm2.answer.question4A": "The ease of converting cryptoassets to fiat currency without impacting the market price",
    "DofpSellForm2.answer.question4B": "The guaranteed profit from selling cryptoassets",
    "DofpSellForm2.answer.question4C": "The security of cryptoasset transactions",
    "DofpSellForm2.answer.question5A": "There is no impact as cryptoassets operate independently of global events",
    "DofpSellForm2.answer.question5B": "There may be an impact on market sentiment and cryptoasset prices",
    "DofpSellForm2.answer.question5C": "The only impact is on traditional financial markets and cryptoassets are not impacted",
    "DofpSellForm2.answer.question6A": "There are no risks to the ownership of my cryptoasset investments when a custodial cryptoasset business becomes insolvent",
    "DofpSellForm2.answer.question6B": "The ownership and access to my cryptoassets may be at risk if the cryptoassets are held in a custodial wallet, compared to holding the cryptoassets in a non-custodial wallet",
    "DofpSellForm2.answer.question6C": "Fluctuating transaction fees on cryptoasset exchanges",
    "DofpSellForm2.answer.question7A": "To predict market trends accurately",
    "DofpSellForm2.answer.question7B": "To determine whether the business is legally able to provide the cryptoasset services",
    "DofpSellForm2.answer.question7C": "To gain tax advantages on cryptoasset profits",
    "DofpSellForm2.answer.question8A": "The volatile nature of cryptoasset prices",
    "DofpSellForm2.answer.question8B": "The advanced technology behind crypoassets guarantees that funds or assets can not be lost",
    "DofpSellForm2.answer.question8C": "I am guaranteed high returns on cryptoasset investments given that they are complex investments",
    "DofpSellForm2.answer.question9A": "There are fewer rules to protect consumers, unlike other mainstream investments.",
    "DofpSellForm2.answer.question9B": "I am guaranteed better returns on cryptoassets compared to mainstream investments.",
    "DofpSellForm2.answer.question9C": "The risks of cryptoassets are comparable to the risks of mainstream investments.",
    "DofpSellForm2.answer.question10A": "The FOS provides full protection against poor investment performance",
    "DofpSellForm2.answer.question10B": "The FOS does not protect against poor investment performance",
    "DofpSellForm2.answer.question10C": "The FOS guarantees a refund for any investment losses",
    "DofpSellForm3.question.question1": "What is your understanding of Banxa's services?",
    "DofpSellForm3.question.question2": "When selling cryptoassets (with the exclusion of stablecoins), sudden price fluctuations may result in:",
    "DofpSellForm3.question.question3": "What is a risk associated with relying solely on custodial or hot wallets for storing your cryptoassets?",
    "DofpSellForm3.question.question4": "Which of the following statements is TRUE regarding retail consumer protections provided for cryptoasset investments.",
    "DofpSellForm3.question.question5": "What is a potential challenge with liquidating your cryptoassets?",
    "DofpSellForm3.question.question6": "Which of the following is TRUE in relation to the risk associated with selling cryptoassets like Bitcoin and Ethereum?",
    "DofpSellForm3.question.question7": "Which of the following is a risk of using unlicensed or unregistered cryptoasset firms to sell your cryptoassets?",
    "DofpSellForm3.question.question8": "How does diversification help mitigate risks with selling cryptoassets?",
    "DofpSellForm3.question.question9": "Why is it inaccurate to directly compare investing in cryptoassets to mainstream investments?",
    "DofpSellForm3.question.question10": "How can operational risks, like cyber-attacks or loss of private keys, lead to potential loss of cryptoassets?",
    "DofpSellForm3.answer.question1A": " I can store my cryptoassets using Banxa's custodial wallet service.",
    "DofpSellForm3.answer.question1B": "I can exchange fiat currency for certain cryptoassets or exchange my cryptoassets for fiat currency using Banxa.",
    "DofpSellForm3.answer.question1C": "I can transfer my cryptoassets to third parties using Banxa.",
    "DofpSellForm3.answer.question2A": "Significant financial losses",
    "DofpSellForm3.answer.question2B": "Government-backed protection",
    "DofpSellForm3.answer.question2C": "Predictable profit margins",
    "DofpSellForm3.answer.question3A": "The risks of using custodial or hot wallets is the same as the risks of using non-custodial or cold wallets ",
    "DofpSellForm3.answer.question3B": "There may be security risks associated with using custodial or hot wallets",
    "DofpSellForm3.answer.question3C": "Non-custodial or cold wallets are less secure than custodial or hot wallets",
    "DofpSellForm3.answer.question4A": "Cryptoassets are largely unregulated and government bodies, such as the Financial Ombudsman Service (FOS) or Financial Service Compensation Scheme (FSCS), provide no protection against poor investment performance or outcomes of cryptoasset investments.",
    "DofpSellForm3.answer.question4B": "The FCA is able to provide a completely risk-free investment environment for cryptoassets.",
    "DofpSellForm3.answer.question4C": "The Financial Ombudsman Service (FOS) will be able to consider complaints related my cryptoasset investments.",
    "DofpSellForm3.answer.question5A": "The insolvency of a cryptoasset firm can impact my ability to buy but does not have any impact on my ability to liquidate my cryptoassets.",
    "DofpSellForm3.answer.question5B": "Cryptoasset sale transactions can incur high transaction costs which may impact the ability to sell cryptoassets.",
    "DofpSellForm3.answer.question5C": "Operational outages can prevent the timely sale of cryptoassets.",
    "DofpSellForm3.answer.question6A": "Cryptoassets like Bitcoin and Ethereum are not considered to be complex investments and are therefore always easy to sell.",
    "DofpSellForm3.answer.question6B": "There is a volatility risk associated with cryptoasset prices, where prices may drop as quickly as it may increase",
    "DofpSellForm3.answer.question6C": "There is no risk of fraud and theft with selling cryptoassets as fraud and theft only occurs with buying cryptoassets",
    "DofpSellForm3.answer.question7A": "There are no risks with using an unlicensed or unregistered cryptoasset firm.",
    "DofpSellForm3.answer.question7B": "Unlicensed and unregistered cryptoassets firms may not abide by any regulatory rules to assist with protecting consumers.",
    "DofpSellForm3.answer.question7C": "Using an unlicensed or unregistered cryptoassets firm may reduce the risk of hacking and theft, and loss of my cryptoassets.",
    "DofpSellForm3.answer.question8A": "By concentrating all investments in a single asset for higher returns",
    "DofpSellForm3.answer.question8B": "By reducing the impact of a valuation decline in any single asset in the portfolio",
    "DofpSellForm3.answer.question8C": "By enabling tax exemptions on cryptoasset sales",
    "DofpSellForm3.answer.question9A": "Because cryptoassets always outperform traditional investments",
    "DofpSellForm3.answer.question9B": "Because cryptoassets offer higher liquidity and lower volatility",
    "DofpSellForm3.answer.question9C": "Because of fundamental differences in regulation, market maturity, and underlying asset structures",
    "DofpSellForm3.answer.question10A": "By causing fluctuations in market demand",
    "DofpSellForm3.answer.question10B": "By compromising the security of stored assets",
    "DofpSellForm3.answer.question10C": "By increasing the value of the affected assets",
    "IdentityQueueStore.name.upNextDofpQuestionnaire1": "Dofp Questionnaire 1",
    "IdentityQueueStore.name.upNextDofpQuestionnaire2": "Dofp Questionnaire 2",
    "IdentityQueueStore.name.upNextDofpQuestionnaire3": "Dofp Questionnaire 3",
    "IdentityQueueStore.name.upNextInvestorCategorisation": "Investor Categorisation",
    "InvestorDeclarationForm.headline.header": "What type of investor are you?",
    "InvestorDeclarationForm.body.body": "As part of the UK Consumer Protection Rules, we will be asking you to confirm the investor category into which you fall. Please carefully review the criteria of each investor category to determine whether you meet the relevant criteria. If you are unable to meet the criteria, unfortunately we will be unable to proceed with the order journey.",
    "InvestorDeclarationForm.investorCategory.restricted": "Restricted",
    "InvestorDeclarationForm.investorCategory.highNetWorth": "High-net-worth",
    "InvestorDeclarationForm.investorCategory.sophisticated": "Sophisticated",
    "Checkbox.text.investorDeclarationTncBold": "I accept that being a {investorCategory} investor will expose me to promotions for investment where there is a risk of losing all the money I invest.",
    "Checkbox.text.investorDeclarationTnc": "{boldText} I am aware that it is open to me to seek professional advice before making any investment in a high-risk investment.",
    "RestrictedInvestor.body.considerationPrompt": "Please review whether you qualify as a restricted investor on the basis that A and B apply to you.",
    "RestrictedInvestor.headline.label": "Restricted Investor",
    "RestrictedInvestor.body.line1": "Putting all your money into a single business or type of investment is risky. Spreading your money across different investments makes you less dependent on any one to do well.",
    "RestrictedInvestor.body.line2": "You should not invest more than 10% of your net assets in high-risk investments. Doing so could expose you to significant losses. For the purpose of this statement, net assets do NOT include: your home (primary residence), your pension (or any pension withdrawals) or any rights under qualifying contracts of insurance.",
    "RestrictedInvestor.body.line3": "For the purposes of this statement high-risk investments are: peer-to-peer (P2P) loans; investment based crowdfunding; units in a long-term asset fund; cryptoassets (such as bitcoin); and unlisted debt and equity (such as in companies not listed on an exchange like the London Stock Exchange).",
    "RestrictedInvestor.body.line4": "Please confirm whether you qualify as a restricted investor on the basis that A and B apply to you.",
    "RestrictedInvestor.label.question1": "Question A",
    "RestrictedInvestor.question.question1": "In the past twelve months have you invested less than 10% of your net assets in high-risk investments (as defined above)?",
    "RestrictedInvestor.answer.question1A": "Yes (I have invested less than 10% of my net assets)",
    "RestrictedInvestor.answer.question1B": "No (I have invested more than 10% of my net assets)",
    "RestrictedInvestor.question.question2": "Over the last twelve months roughly what percentage of your net assets have you invested in high-risk investments (as defined above)?",
    "RestrictedInvestor.label.question3": "Question B",
    "RestrictedInvestor.question.question3": "In the next twelve months do you intend to limit your investment in high-risk investments (as defined above) to less than 10% of your net assets?",
    "RestrictedInvestor.answer.question3A": "Yes (I intend to invest less than 10% of my net assets)",
    "RestrictedInvestor.answer.question3B": "No (I intend to invest more than 10% of my net assets)",
    "RestrictedInvestor.question.question4": "In the next twelve months roughly what percentage of your net asset do you intend to invest in high-risk investments (as defined above)?",
    "HighNetWorthInvestor.headline.label": "High-net-worth Investor",
    "HighNetWorthInvestor.body.line1": "Please confirm whether you qualify as a high-net-worth investor on the basis that A or B apply to you.",
    "HighNetWorthInvestor.body.line2": "In the last financial year did you have:",
    "HighNetWorthInvestor.label.question1": "Question A",
    "HighNetWorthInvestor.question.question1": "an annual income of £100,000 or more? Income does NOT include any one-off pension withdrawals.",
    "HighNetWorthInvestor.answer.question1A": "No",
    "HighNetWorthInvestor.answer.question1B": "Yes",
    "HighNetWorthInvestor.question.question2": "Please specify your income (as defined above) to the nearest £10,000 in the last financial year",
    "HighNetWorthInvestor.income.errorMsg": "Invalid income input",
    "HighNetWorthInvestor.label.question3": "Question B",
    "HighNetWorthInvestor.question.question3": "net assets of £250,000 or more? Net assets do NOT include: your home (primary residence), your pension (or any pension withdrawals) or any rights under qualifying contracts of insurance.",
    "HighNetWorthInvestor.answer.question3A": "No",
    "HighNetWorthInvestor.answer.question3B": "Yes",
    "HighNetWorthInvestor.question.question4": "Please specify your net assets (as defined above) to the nearest £100,000 in the last financial year",
    "HighNetWorthInvestor.netAssets.errorMsg": "Invalid net assets input",
    "Snackbar.text.identityUpdate": "Your identity requirements have been updated based on your submission.",
    "dofpFailureModal.text.title": "That's not quite right",
    "dofpFailureModal.text.riskSummaryLink": "website and risk summary.",
    "dofpFailureModal.text.iUnderstand": "I understand",
    "dofpFailureModal.modal1.primaryBtnText": "Continue and try again",
    "dofpFailureModal.modal.secondaryBtnText": "I do not wish to continue",
    "dofpFailureModal.modal1.body": "Please be informed that the purchase or sale of cryptoassets may not be appropriate for you based on this assessment. You have the option to retake the assessment and we encourage that you take the time to understand and consider the risks involved in purchasing or selling cryptoassets. For more information, please refer to our {riskSummaryLink}",
    "dofpFailureModal.modal2.body": "Please be informed that the purchase or sale of cryptoassets may not be appropriate for you based on this assessment. You have the option to retake the assessment after 24 hours, and we encourage that you take the time to understand and consider the risks involved in purchasing or selling cryptoassets. For more information, please refer to our {riskSummaryLink}",
    "dofpFailureModal.modal3.body": "Please be informed that the purchase or sale of cryptoassets may not be appropriate for you based on this assessment. We encourage that you take the time to understand and consider the risks involved in purchasing or selling cryptoassets. For more information, please refer to our {riskSummaryLink} Please note that the option to retake the assessment will only be available after 24 hours.",
    "DofpNotify.modal.title1": "{firstName}, this is a high-risk investment.",
    "DofpNotify.modal.title2": "This is a high-risk investment.",
    "DofpNotify.modal.body1": "How would you feel if you lost the money you’re about to invest? {riskSummaryLink}",
    "DofpNotify.modal.body2": "Take 2 min to learn more.",
    "ButtonBase.label.iUnderstandAndProceed": "I understand and would like to proceed",
    "ButtonBase.label.iUnderstandAndLeave": "I would like to leave the order request",
    "IdentityQueueStore.name.upNextCoolingOff": "Cooling off period",
    "ButtonBase.label.continueMyOrder": "I'd like to continue my order",
    "ButtonBase.label.noLongerContinue": "I no longer want to complete my order",
    "FormAlert.text.pleaseNote": "Please note: ",
    "PaymentCooling.title.coolingPeriod": "Your cooling off period has begun",
    "PaymentCooling.bodyCopy.paragraph1": "Please be informed that your instructions to create the order has been received. In accordance with the UK Consumer Protection Rules we are providing you with a 24 hour cooling off period so that you are able to adequately asses the risks involved with investing in cryptoassests before placing your money into the investment.",
    "PaymentCooling.bodyCopy.paragraph2": "For further information on the risks associated with the cryptoassets, please refer to our {riskSummaryLink}.",
    "PaymentCooling.link.riskSummary": "Risk Summary",
    "PaymentCooling.bodyCopy.endMessage": "We’ll send you an email when the cooling off is complete, which will provide you with the options to continue or leave the order journey.",
    "ButtonBase.label.iUnderstand": "I Understand",
    "OrderRecoveryModal.label.send": "You send",
    "identityStore.name.upNextBillingDetails": "Invoice Details",
    "identityStore.name.upNextProofOfAddress": "Proof of Address",
    "identityStore.name.upNextSof": "Source of Income",
    "identityStore.name.upNextPurposeOfTransaction": "Purpose of the Transaction",
    "identityStore.name.upNextSofDocUpload": "Uploading Source of Funds Document",
    "identityStore.name.upNextDocUpload": "Document Upload",
    "identityStore.name.upNextDocNumber": "Document Number",
    "identityStore.name.upNextSelfieDocUpload": "Upload Selfie Document",
    "identityStore.name.upNextOccupation": "Occupation",
    "identityStore.name.upNextPepSof": "PEP Source of Funds",
    "identityStore.name.upNextPepSofDoc": "PEP Source of Funds Document upload",
    "UpdateDetailForm.label.upNextSSN": "SSN Tax Number",
    "UpdateDetailForm.label.upNextDlocalCpf": "Your Cadastro de Pessoas Físicas",
    "UpdateDetailForm.label.upNextDlocalSaid": "Your South African Identity Card",
    "UpdateDetailForm.label.upNextDlocalCurp": "Your Mexican Identity Card",
    "UpdateDetailForm.label.upNextDlocalDni": "Your Documento Nacional de Identidad",
    "UpdateDetailForm.label.upNextDlocalCc": "Your Cédula de Ciudadanía",
    "UpdateDetailForm.label.upNextDlocalCi": "Your Cédula de identidad",
    "UpdateDetailForm.label.upNextDlocalTic": "Your Thai Identity Card",
    "IdentityQueueStore.name.updateDetails": "Update details",
    "ZerohashTncForm.label.title": "Please accept our terms and conditions",
    "IdentityQueueStore.name.upNextZerohashTnc": "Terms and conditions",
    "IdentityQueueStore.name.upNextScamCheck": "Scam Safety Assessment",
    "ScamCheckForm.headline.header": "Scam Safety Assessment",
    "ScamCheckForm.body.body1": "In the interest of protecting our customers, especially regarding scams, we would like to ask you a few questions. \nScammers target cryptocurrency users in many different and complex ways. \nRemember that something that sounds too good to be true - most often is some type of scam. \nIf anybody is guiding you how to respond it's very likely they are trying to scam you.",
    "ScamCheckForm.question.question1": "Has anybody who you only know online (e.g. friend, partner or account manager) guided you to purchase cryptocurrency through us?",
    "ScamCheckForm.answer.question1A": "Yes",
    "ScamCheckForm.answer.question1B": "No",
    "ScamCheckForm.question.question2": "Has anybody given you a wallet address to send your cryptocurrency for your investment?",
    "ScamCheckForm.answer.question2A": "Yes",
    "ScamCheckForm.answer.question2B": "No",
    "ScamCheckForm.question.question3": "Have you been asked to pay a certain amount (e.g. profit/interest fee) in order to withdraw your profits or recover your lost funds?",
    "ScamCheckForm.answer.question3A": "Yes",
    "ScamCheckForm.answer.question3B": "No",
    "ScamCheckForm.question.question4": "Are you purchasing cryptocurrency to use for a job/employment opportunity?",
    "ScamCheckForm.answer.question4A": "Yes",
    "ScamCheckForm.answer.question4B": "No",
    "ScamCheckForm.question.question5": "Are you getting commission by completing online tasks, purchasing or reselling goods?",
    "ScamCheckForm.answer.question5A": "Yes",
    "ScamCheckForm.answer.question5B": "No",
    "ScamCheckForm.question.question6": "Is the destination for your cryptocurrency a website where direct purchasing is not an option?",
    "ScamCheckForm.answer.question6A": "Yes",
    "ScamCheckForm.answer.question6B": "No",
    "PrimerapForm.notifyCustomer.pleaseWait": "Please wait...",
    "PrimerapForm.notifyCustomer.paymentInProgress": "Your payment is currently being processed.",
    "PrimerapForm.error.paymentFailed": "Payment failed. Please try again.",
    "IdentityDashboard.label.headline": "Your Identity Progress",
    "IdentityDashboard.label.body": "To complete your order we need a few important pieces of information from you. We collect these to prevent against fraud and identity theft, and to satisfy our legal and regulatory requirements.",
    "ButtonBase.label.continueToPayment": "Continue",
    "ButtonBase.label.continueTo": "Continue",
    "IdentityDashboard.guide.completeAll": "Complete all identity steps to enable {nextStep}.",
    "IdentityDashboard.description.personalDetails": "Provide your personal details and address",
    "IdentityDashboard.description.poa": "Upload a copy of your bill showing your address",
    "IdentityDashboard.description.sof": "Provide your source of funds information",
    "IdentityDashboard.description.pot": "Describe the purpose of your transaction",
    "IdentityDashboard.description.sofDocument": "Upload proper document(s) to prove your source of funds",
    "IdentityDashboard.description.document": "Upload government issued document(s) to prove your identity",
    "IdentityDashboard.description.documentNumber": "Provide your government issued identity number",
    "IdentityDashboard.description.selfie": "Provide a selfie of yourself holding a government issued ID",
    "IdentityDashboard.description.occupation": "Provide your occupation",
    "IdentityDashboard.description.pepSof": "Provide your source of funds information",
    "IdentityDashboard.description.pepSofDocument": "Upload document(s) to verify your source of funds",
    "IdentityDashboard.description.usTaxId": "Provide your tax information",
    "IdentityDashboard.description.dlocalCpf": "Provide your cadastro de pessoas físicas number",
    "IdentityDashboard.description.dlocalSaid": "Provide your South African identity card number",
    "IdentityDashboard.description.dlocalCurp": "Provide your Mexican identity card number",
    "IdentityDashboard.description.dlocalDni": "Provide your documento nacional de identidad number",
    "IdentityDashboard.description.dlocalCc": "Provide your cédula de ciudadanía number",
    "IdentityDashboard.description.dlocalCi": "Provide your cédula de identidad number",
    "IdentityDashboard.description.dlocalTic": "Provide your Thai identity card number",
    "IdentityDashboard.description.walletOwnership": "Provide proof of your wallet address ownership",
    "IdentityDashboard.description.dofpQuestionnaire": "Complete an user appropriateness questionnaire",
    "IdentityDashboard.description.dofpCategory": "Complete an investor type questionnaire",
    "IdentityDashboard.description.dofpCoolingOff": "Cooling off period",
    "IdentityDashboard.description.updateDetails": "Update your details you have provided with us",
    "IdentityDashboard.description.zerohashTnc": "Confirm our terms and conditions",
    "FormAlert.text.priceDisclaimer": "* Final price shown at payment",
    "FormSelect.error.countryErrorMsg": "Please select a valid country, {country} is unsupported"
}
